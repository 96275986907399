
import firebase from 'firebase/app'
import 'firebase/database'

import { v4 as uuidv4 } from 'uuid'

export default {

    namespaced: true,
    state:
    {
        libretaDirecciones: [],
        estatusSolicitud: '',
        mensajeAlerta: {
            status: false,
            titulo: '',
            mensaje: '',
            variant: '',
            toaster: '',
        },
    },
    mutations:
    {
        setReiniciarDirecciones(state)
        {
          state.libretaDirecciones = []
        },
        setDireccion(state, direccion)
        {
            state.libretaDirecciones.push(direccion)
        },
        modificarDireccion(state, direccion)
        {
            state.libretaDirecciones = state.libretaDirecciones.map(item => item.idDireccion === direccion.idDireccion ? direccion : item)
        },
        elimarDireccion(state, id)
        {
            state.libretaDirecciones = state.libretaDirecciones.filter(item => item.idDireccion !== id)
        },
        setEstatusSolicitud(state, status)
        {
            state.estatusSolicitud = status
        },
        setMensajeAlerta(state, alerta)
        {
            state.mensajeAlerta.status = alerta.status
            state.mensajeAlerta.titulo = alerta.titulo
            state.mensajeAlerta.mensaje = alerta.mensaje
            state.mensajeAlerta.variant = alerta.variant
            state.mensajeAlerta.toaster = alerta.toaster
        },
    },
    actions:
    {

        //////////////////////////////////////////////////////////////
        guardarDirecciones({ commit }, direccion)
        {
            try
            {

                const db = firebase.database()
                const uid = firebase.auth().currentUser.uid

                const idDireccion = uuidv4()

                /* ////// Guardar direcciones ////// */
                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + uid + '/Mis direcciones/' + idDireccion)
                .set(direccion)

                const alerta =
                {
                status: true,
                titulo: 'Dirección guardada',
                mensaje: 'Se ha añadido una nueva dirección a su libreta de direcciones.',
                variant: 'success',
                toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)

                /* Añadir direccion a State */
                const nuevaDireccion = Object.assign({ direccionCompleta: direccion.direccion, idDireccion: idDireccion }, direccion)
                console.log(nuevaDireccion)
                commit('setDireccion', nuevaDireccion)

            } catch (error)
            {
                const alerta =
                {
                status: false,
                titulo: 'Ha ocurrido un error',
                mensaje: 'ERROR: ' + error,
                variant: 'danger',
                toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)
            }
        },
        //////////////////////////////////////////////////////////////
        getDirecciones({ commit })
        {
            // console.log('---')
            try
            {
                commit('setEstatusSolicitud', 'cargando')

                const db = firebase.database()
                const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
                const uid = datosUsuario.uid

                const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + uid + '/Mis direcciones')

                commit('setReiniciarDirecciones')

                /* ////// Obtener listado de direcciones ////// */
                referencia.once('value', function(snapshot)
                {
                const data = snapshot.val()
                //const arrayDatos = []

                for (let id in data)
                {
                    data[id].direccionCompleta = data[id]['direccion']
                    data[id].idDireccion = id
                    commit('setDireccion', data[id])
                    // console.log(data[id])
                    //arrayDatos.push(data[id])
                }

                //console.log(arrayDatos)
                //commit('cargarLibretaDirecciones', arrayDatos)
                })
            }
            catch (error)
            {
                commit('setEstatusSolicitud', 'error')
                throw error
            }
            finally
            {
                commit('setEstatusSolicitud', 'finalizado')
            }

        },
        //////////////////////////////////////////////////////////////
        eliminarDirecciones({ commit }, idDireccion)
        {

            try
            {

                const db = firebase.database()
                const uid = firebase.auth().currentUser.uid

                /* ////// Eliminar direcciones ////// */
                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + uid + '/Mis direcciones/' + idDireccion).remove()

                const alerta =
                {
                status: true,
                titulo: 'Dirección eliminada',
                mensaje: 'Se ha eliminado la dirección de su libreta de direcciones.',
                variant: 'success',
                toaster: 'b-toaster-top-right'
                }

                commit('elimarDireccion', idDireccion)
                commit('setMensajeAlerta', alerta)
            }
            catch(error)
            {
                const alerta =
                {
                status: false,
                titulo: 'Ha ocurrido un error',
                mensaje: 'ERROR: ' + error,
                variant: 'danger',
                toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)
            }

        },
        //////////////////////////////////////////////////////////////
        modificarDirecciones({ commit }, direccion)
        {

            try
            {
                const db = firebase.database()
                const uid = direccion.idDireccionCliente !== '' && direccion.idDireccionCliente !== undefined ? direccion.idDireccionCliente : firebase.auth().currentUser.uid
                console.log(direccion)
                const informacionDireccion =
                {
                    ['lat direccion']: direccion.latDireccion,
                    ['lng direccion']: direccion.lngDireccion,
                    ['nombre']: direccion.nombreDireccion,
                    //['apellidos']: direccion.apellidoDireccion,
                    ['telefono']: direccion.telefonoDireccion,
                    ['empresa']: direccion.empresaDireccion,
                    ['informacion adicional']: direccion.informacionAdicional,
                    ['correo electronico']: direccion.correoDireccion,
                    ['direccion']: direccion.direccionCompleta,
                    ['puerta']: direccion.puerta,
                    ['piso']: direccion.piso,
                    ['codigoPaisTelefono']: direccion.codigoPaisTelefono,
                    ['telefonoTemporal']: direccion.telefonoTemporal,
                    ['direccion guardada']: direccion['direccion guardada'],
                    ['id vinculada']: direccion['id vinculada'],
                    ['isCollectionAddress']: direccion.isCollectionAddress
                }

                /* ////// Modificar direcciones ////// */
                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + uid + '/Mis direcciones/' + direccion.idDireccion)
                .update(informacionDireccion)

                const alerta =
                {
                    status: true,
                    titulo: 'Dirección modificada',
                    mensaje: 'Se ha modificado la dirección seleccionada.',
                    variant: 'success',
                    toaster: 'b-toaster-top-right'
                }

                /* Modificar direccion en State */
                const direccionModificada = Object.assign(informacionDireccion, { direccionCompleta: direccion.direccionCompleta }, { idDireccion: direccion.idDireccion })

                commit('modificarDireccion', direccionModificada)
                commit('setMensajeAlerta', alerta)
                console.log(direccionModificada)

            }
            catch(error)
            {
                const alerta =
                {
                    status: false,
                    titulo: 'Ha ocurrido un error',
                    mensaje: 'ERROR: ' + error,
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)
            }
        },

    },
}