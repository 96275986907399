/* eslint-disable */
import firebase from 'firebase/app'
import 'firebase/database'

//Fecha de hoy
const fechaHoy = new Date().setHours(0, 0, 0, 0)
const fechaAnterior = fechaHoy - 604800000

const timeStampFinal = new Date(fechaHoy).setHours(23, 59, 59, 59)
const fechaInicio = new Date(fechaAnterior).setHours(0, 0, 0, 0)

export default {

  namespaced: true,
  state:
  {
    loaderActivoSol:false,
    estatusSolicitud: '',
    existeUsuario: false,
    solicitudesFinalizadas: [],
    solicitudesProgramadas: [],
    solicitudesEnCurso: [],
    listadoIdEntregaAdministrador: [],
    solicitudesEntregadasRepartidor: [],
    idVinculadaSoporteTecnico: '',
    totalSolicitudesEnCurso: [],
    totalSolicitudesProgramadas: [],
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    }
  },
  mutations:
  {
    setReiniciarTotalSolicitudes(state)
    {
      state.totalSolicitudesEnCurso = []
      state.totalSolicitudesProgramadas = []
    },
    setTotalSolicitudesEnCurso(state, total)
    {
      state.totalSolicitudesEnCurso.push(total)
    },
    setTotalSolicitudesProgramadas(state, total)
    {
      state.totalSolicitudesProgramadas.push(total)
    },
    setTotalNuevasSolicitudesEnCurso(state, nuevaSolicitud)
    {
      const arrayTemporal = state.totalSolicitudesEnCurso

      arrayTemporal.push(nuevaSolicitud)

      let hash = {}
      state.totalSolicitudesEnCurso = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

    },
    setTotalNuevasSolicitudesProgramadas(state, nuevaSolicitud)
    {
      const arrayTemporal = state.totalSolicitudesProgramadas

      arrayTemporal.push(nuevaSolicitud)

      let hash = {}
      state.totalSolicitudesProgramadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

    },
    setTotalNuevasSolicitudesFinalizadas(state, solicitud)
    {
      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      if(tipoUsuario === 'Admin')
      {
        state.totalSolicitudesEnCurso = state.totalSolicitudesEnCurso.filter((item) => item.idEntrega !== solicitud.id)
      }

      if(tipoUsuario !== 'Admin')
      {
        state.totalSolicitudesEnCurso = state.totalSolicitudesEnCurso.filter((item) => item.idSolicitudCompleta !== solicitud.id)
      }
    },

    setIdVinculadaSoporteTecnico(state, idVinculada)
    {
      state.idVinculadaSoporteTecnico = idVinculada
    },
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setReiniciarSolicitudes(state)
    {
      state.solicitudesFinalizadas = []
      state.solicitudesProgramadas = []
      state.solicitudesEnCurso = []
    },
    setReiniciarSolicitudesEntregadasRepartidor(state)
    {
      state.solicitudesEntregadasRepartidor = []
    },
    setSolicitudesEntregadasRepartidor(state, solicitud)
    {
      state.solicitudesEntregadasRepartidor.push(solicitud)
    },
    setSolicitudesFinalizadas(state, solicitud)
    {
      state.solicitudesFinalizadas.push(solicitud)
    },
    setSolicitudesProgramadas(state, solicitud)
    {
      state.solicitudesProgramadas.push(solicitud)
    },
    setSolicitudesEnCurso(state, solicitud)
    {
      state.solicitudesEnCurso.push(solicitud)
    },
    setNuevasSolicitudesEnCurso(state, solicitud)
    {
      const arrayTemporal = state.solicitudesEnCurso

      arrayTemporal.push(solicitud)

      let hash = {}
      state.solicitudesEnCurso = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

    },
    setNuevasSolicitudesProgramadas(state, solicitud)
    {
      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      const arrayTemporal = state.solicitudesProgramadas

      arrayTemporal.push(solicitud)

      let hash = {}
      //state.solicitudesProgramadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idSolicitudCompleta] ? false : hash[solicitudes.idSolicitudCompleta] = true)

      if(tipoUsuario === 'Admin')
      {
        state.solicitudesProgramadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)
      }

      if(tipoUsuario !== 'Admin')
      {
        state.solicitudesProgramadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idSolicitudCompleta] ? false : hash[solicitudes.idSolicitudCompleta] = true)
      }

    },
    setNuevasSolicitudesFinalizadas(state, solicitud)
    {

      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      const arrayTemporal = state.solicitudesFinalizadas

      arrayTemporal.push(solicitud)

      let hash = {}

      if(tipoUsuario === 'Admin')
      {
        state.solicitudesFinalizadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)
      }

      if(tipoUsuario !== 'Admin')
      {
        state.solicitudesFinalizadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idSolicitudCompleta] ? false : hash[solicitudes.idSolicitudCompleta] = true)
      }

    },
    modificarSolicitudesEnCurso(state, solicitud)
    {

      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      if(tipoUsuario === 'Admin')
      {
        state.solicitudesEnCurso.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.nombreRepartidor = solicitud.nombreRepartidor
            listado.vehiculoRepartidor = solicitud.vehiculoRepartidor
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })
      }

      if(tipoUsuario !== 'Admin')
      {
        state.solicitudesEnCurso.map(function (listado) {
          if(listado.idSolicitudCompleta === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.nombreRepartidor = solicitud.nombreRepartidor
            listado.vehiculoRepartidor = solicitud.vehiculoRepartidor
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })
      }

    },
    modificarSolicitudesProgramadas(state, solicitud)
    {
      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      if(tipoUsuario === 'Admin')
      {
        state.solicitudesProgramadas.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idEntrega !== solicitud.id)
      }

      if(tipoUsuario !== 'Admin')
      {
        state.solicitudesProgramadas.map(function (listado) {
          if(listado.idSolicitudCompleta === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idSolicitudCompleta !== solicitud.id)
      }

    },
    modificarSolicitudesFinalizadas(state, solicitud)
    {
      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const tipoUsuario = usuarioAutentificado.usuario

      if(tipoUsuario === 'Admin')
      {
        state.solicitudesFinalizadas.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idEntrega !== solicitud.id)
      }

      if(tipoUsuario !== 'Admin')
      {
        state.solicitudesFinalizadas.map(function (listado) {
          if(listado.idSolicitudCompleta === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idSolicitudCompleta !== solicitud.id)
      }

    },
    setReprogramarSolicitudState(state, solicitud)
    {
      if(state.detallesSolicitud.idSolicitud === solicitud.idSolicitud)
      {
        state.detallesSolicitud.estatusSolicitud = solicitud.estatusSolicitud.toLowerCase()
        state.detallesSolicitud.fechaRecoleccion = solicitud.fechaRecoleccion

      }
    },
    setListadoIdEntrega(state, idEntrega)
    {
      state.listadoIdEntregaAdministrador = idEntrega
    },
    setDetallesSolicitud(state, solicitud)
    {
      state.detallesSolicitud = solicitud
    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
    setLoaderActivo(state, estado)
    {
      state.loaderActivo =  estado
      console.log(state.loaderActivo);
    }
  },
  actions:
  {
    activarDesactivarLoader({ commit }, estado){
      commit('setLoaderActivo',estado)

    },
    getHistorialSolicitudes({ commit }, informacionFiltro)
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudes')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {

          referencia.orderByChild('fechaSolicitud')
          .startAt(informacionFiltro.fechaInicio)
          .endAt(informacionFiltro.fechaFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()

            data.timestampSolicitud = data['fechaSolicitud']

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              // console.log(data.notaAdicional)
              // console.log(data)
            }

          })

        }

        if(tipoUsuario !== 'Admin')
        {
          referencia.orderByChild('idVinculada_Timestamp')
          .startAt(uid + "_" + informacionFiltro.fechaInicio)
          .endAt(uid + "_" + informacionFiltro.fechaFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()

            data.timestampSolicitud = data['fechaSolicitud']

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              // console.log(data)
            }

          })
        }

        commit('setEstatusSolicitud', 'finalizado')

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
    },
    getSolicitudesPorCliente({ commit }, clienteSeleccionado)
    {

      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudes')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {

          referencia.orderByChild('clienteID_Timestamp')
          .startAt(clienteSeleccionado.fechaInicio)
          .endAt(clienteSeleccionado.fechaFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()

            data.timestampSolicitud = data['fechaSolicitud']

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              console.log(data)
            }

          })

        }

        if(tipoUsuario !== 'Admin')
        {
          referencia.orderByChild('clienteID_Timestamp')
          .startAt(clienteSeleccionado.fechaInicio)
          .endAt(clienteSeleccionado.fechaFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()

            data.timestampSolicitud = data['fechaSolicitud']

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              // console.log(data)
            }

          })
        }

        commit('setEstatusSolicitud', 'finalizado')

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }

    },
    //////////////////////////////////////////////////////////////
    getSolicitudesAdministrador({ commit })
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudes')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {

          referencia.orderByChild('fechaSolicitud')
          .startAt(fechaInicio)
          .endAt(timeStampFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            for (const id in data['solicitud entrega'])
            {
              if(data['solicitud entrega'][id].estatusNuevoSolicitud !== undefined)
              {

                data['solicitud entrega'][id].timestampSolicitud = data['fechaSolicitud']

                const estatusNuevo = data['solicitud entrega'][id].estatusNuevoSolicitud

                if (estatusNuevo === 'Programado')
                {
                  const date = new Date(data['fechaSolicitud'])

                  data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                  data['solicitud entrega'][id].estatusSolicitud = data.estatusSolicitud.toLowerCase()
                  data['solicitud entrega'][id].estatusNuevoSolicitud = String(estatusNuevo).toLowerCase()
                  data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  commit('setSolicitudesProgramadas', { ...data['solicitud entrega'][id], ...data})
                }

                if (estatusNuevo == 'En establecimiento' || estatusNuevo === 'Recogido' || estatusNuevo === 'Aceptado' ||
                estatusNuevo === 'En curso' || estatusNuevo ===  'Conectando' || estatusNuevo ===  'Recolectando' ||
                estatusNuevo === 'Buscando' || estatusNuevo === 'En punto de entrega' || estatusNuevo === 'Notificado')
                {

                  const date = new Date(data['fechaSolicitud'])

                  const fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

                  data['solicitud entrega'][id].estatusSolicitud = data.estatusSolicitud.toLowerCase()
                  data['solicitud entrega'][id].idSolicitudCompleta = data['solicitud entrega'][id].idSolicitud

                  commit('setSolicitudesEnCurso', { ...data, ...data['solicitud entrega'][id], estatusNuevoSolicitud: String(estatusNuevo).toLowerCase(), fechaSolicitud: fechaSolicitud })

                }

              }
            }

          })

        }
        else
        {
          //este modifica la tabla de lideres y soporte tecnico
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()
            console.log('3')
            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
            const estatusNuevoSolicitud = data['estatusNuevoSolicitud'].toLowerCase()

            data.timestampSolicitud = data['fechaSolicitud']

            if (estatusSolicitud === 'entregado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              //data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase() //time real en nuevo estatus
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              console.log(data)
            }

            if (estatusSolicitud === 'programado')
            {
              const date = new Date(data['fechaSolicitud'])

              data.idSolicitudCompleta = data.idSolicitud
              data.fechaSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesProgramadas', data)
              console.log(data)
            }

            if (estatusSolicitud == 'en establecimiento'|| estatusNuevoSolicitud === 'recogido' || estatusNuevoSolicitud === 'en curso' ||
            estatusNuevoSolicitud === 'en establecimiento' || estatusSolicitud === 'aceptado' ||
            estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusNuevoSolicitud === 'notificado'
            || estatusSolicitud ===  'recolectando' || estatusNuevoSolicitud === 'en punto de entrega' || estatusNuevoSolicitud === 'buscando')
            {
              const date = new Date(data['fechaSolicitud'])

              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesEnCurso', data)
              console.log(data)
            }

          })

        }

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }
    },
    //////////////////////////////////////////////////////////////
    getNuevasSolicitudesAdministrador({ commit })
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {

          let vecesEjecutado = 1

          referencia.orderByChild('fechaSolicitud')
          .startAt(fechaInicio)
          .endAt(timeStampFinal)
          .on('child_changed', function (snapshot)
          {

            if(vecesEjecutado === 1)
            {

              const data = snapshot.val()

              for (const id in data['solicitud entrega'])
              {
                if(data['solicitud entrega'][id].estatusNuevoSolicitud !== undefined)
                {

                  const estatusNuevo = data['solicitud entrega'][id].estatusNuevoSolicitud
                  const date = new Date(data['fechaSolicitud'])

                  data['solicitud entrega'][id].timestampSolicitud = data['fechaSolicitud']

                  if (estatusNuevo === 'Entregado' || estatusNuevo === 'Cancelado')
                  {

                    data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

                    commit('setNuevasSolicitudesFinalizadas', { ...data, ...data['solicitud entrega'][id], estatusNuevoSolicitud: String(estatusNuevo).toLowerCase() })
                    console.log(data)

                    //Cambiar estatus de solicitudes finalizadas
                    commit('modificarSolicitudesFinalizadas', {id: data['solicitud entrega'][id].idEntrega, estatus: data.estatusSolicitud.toLowerCase(), estatusNuevoSolicitud: String(estatusNuevo).toLowerCase() })
                  }

                  if(estatusNuevo === 'Programado')
                  {
                    data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

                    commit('setNuevasSolicitudesProgramadas', { ...data, ...data['solicitud entrega'][id], estatusNuevoSolicitud: String(estatusNuevo).toLowerCase() })
                    console.log(data)

                    //Cambiar estatus de solicitudes finalizadas
                    commit('modificarSolicitudesProgramadas', {id: data['solicitud entrega'][id].idEntrega, estatus: data.estatusSolicitud.toLowerCase(), estatusNuevoSolicitud: String(estatusNuevo).toLowerCase() })
                  }

                  if (estatusNuevo == 'En establecimiento' || estatusNuevo === 'Recogido' || estatusNuevo === 'Aceptado' ||
                  estatusNuevo === 'En curso' || estatusNuevo ===  'Conectando' || estatusNuevo ===  'Recolectando' ||
                  estatusNuevo === 'Buscando' || estatusNuevo === 'En punto de entrega' || estatusNuevo === 'Notificado')
                  {

                    data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                    data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

                    commit('setNuevasSolicitudesEnCurso', { ...data, ...data['solicitud entrega'][id], estatusNuevoSolicitud: String(estatusNuevo).toLowerCase() })

                    //Cambiar estatus de solicitudes en curso
                    commit('modificarSolicitudesEnCurso', {
                      id: data['solicitud entrega'][id].idEntrega,
                      estatus: data.estatusSolicitud.toLowerCase(),
                      vehiculoRepartidor: data['vehiculoRepartidor'],
                      nombreRepartidor: data['nombreRepartidor'],
                      estatusNuevoSolicitud: String(estatusNuevo).toLowerCase()
                    })

                  }

                }
              }

            }

            vecesEjecutado++

            // console.log(vecesEjecutado)

            if(vecesEjecutado >= 2)
            {
              vecesEjecutado = 1
            }

            // console.log(vecesEjecutado)
            // console.log("cambiadoooo")
          })

        }
        else
        {

          let vecesEjecutado = 1

          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_changed', function (snapshot)
          {

            if(vecesEjecutado === 1)
            {

              const data = snapshot.val()

                //const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
                const estatusSolicitud = data['estatusNuevoSolicitud'].toLowerCase()

                data.timestampSolicitud = data['fechaSolicitud']

                if (estatusSolicitud === 'entregado' || estatusSolicitud === 'cancelado')
                {
                  const date = new Date(data['fechaSolicitud'])

                  //Agregar nueva solicitud finalizada
                  data.idSolicitudCompleta = data.idSolicitud
                  data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
                  //data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
                  data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  commit('setNuevasSolicitudesFinalizadas', data)
                  console.log(data)

                  //Cambiar estatus de solicitudes finalizadas
                  commit('modificarSolicitudesFinalizadas', {id: data.idSolicitud, estatus: data.estatusSolicitud.toLowerCase(), vehiculoRepartidor: data['vehiculoRepartidor'], nombreRepartidor: data['nombreRepartidor'], estatusNuevoSolicitud: data.estatusNuevoSolicitud })
                }

                if (estatusSolicitud === 'programado')
                {
                  const date = new Date(data['fechaSolicitud'])

                  //Agregar nueva solicitud programada
                  data.idSolicitudCompleta = data.idSolicitud
                  data.fechaSolicitud = data.estatusSolicitud.toLowerCase()
                  data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  commit('setNuevasSolicitudesProgramadas', data)
                  console.log(data)

                  //Cambiar estatus de solicitudes programadas
                  commit('modificarSolicitudesProgramadas', {id: data.idSolicitud, estatus: estatusSolicitud, estatusNuevoSolicitud: data.estatusNuevoSolicitud })
                }

                if (estatusSolicitud === 'buscando'||estatusSolicitud === 'notificado'||estatusSolicitud === 'aceptado'||
                estatusSolicitud === 'en establecimiento'||estatusSolicitud === 'recogido'||estatusSolicitud === 'en curso'
                || estatusSolicitud === 'en punto de entrega')
                {
                  const date = new Date(data['fechaSolicitud'])

                  //Agregar nueva solicitud en curso
                  data.idSolicitudCompleta = data.idSolicitud
                  data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
                  data.estatusNuevoSolicitud = data.estatusNuevoSolicitud.toLowerCase()
                  data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  commit('setNuevasSolicitudesEnCurso', data)
                  console.log(data)

                 //Cambiar estatus de solicitudes en curso
                 commit('modificarSolicitudesEnCurso', {id: data.idSolicitud, estatus: data.estatusSolicitud.toLowerCase(), vehiculoRepartidor: data['vehiculoRepartidor'], nombreRepartidor: data['nombreRepartidor'], estatusNuevoSolicitud: data.estatusNuevoSolicitud })
                }

            }

            vecesEjecutado++

            if(vecesEjecutado >= 2)
            {
              vecesEjecutado = 1
            }

            console.log("cambiadoooo")

          })

        }

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getSolicitudesSoporteTecnico({ commit }, informacionSolicitud)
    {
      try
      {
        const db = firebase.database()

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudes')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        referencia.orderByChild('idVinculada_Timestamp')
        //.equalTo(informacionSolicitud.idVinculada)
        .startAt(informacionSolicitud.idVinculada + '_' + informacionSolicitud.fechaInicio)
        .endAt(informacionSolicitud.idVinculada + '_' + informacionSolicitud.fechaFinal)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
          data.timestampSolicitud = data['fechaSolicitud']

          if(informacionSolicitud.tipoSolicitud === 'historial')
          {

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //data['solicitud entrega'][id].idEntrega = id
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesFinalizadas', data)
              console.log(data)
            }

          }

          if(informacionSolicitud.tipoSolicitud === 'en curso')
          {

            if (estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusSolicitud ===  'recolectando')
            {
              const date = new Date(data['fechaSolicitud'])

              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesEnCurso', data)
              console.log(data)
            }
          }

          if(informacionSolicitud.tipoSolicitud === 'programado')
          {
            if (estatusSolicitud === 'programado')
            {
              const date = new Date(data['fechaSolicitud'])

              data.idSolicitudCompleta = data.idSolicitud
              data.fechaSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setSolicitudesProgramadas', data)
              console.log(data)
            }
          }

        })

        commit('setEstatusSolicitud', 'finalizado')

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getNuevasSolicitudesSoporteTecnico({ commit, state }, informacionSolicitud)
    {
      try
      {
        const db = firebase.database()

        commit('setIdVinculadaSoporteTecnico', informacionSolicitud.idVinculada)

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        referencia.orderByChild('idVinculada_Timestamp')
        //.equalTo(informacionSolicitud.idVinculada)
        .startAt(informacionSolicitud.idVinculada + '_' + informacionSolicitud.fechaInicio)
        .endAt(informacionSolicitud.idVinculada + '_' + informacionSolicitud.fechaFinal)
        .on('child_changed', function (snapshot)
        {

          const data = snapshot.val()

          const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
          data.timestampSolicitud = data['fechaSolicitud']

          if(informacionSolicitud.tipoSolicitud === 'historial' && state.idVinculadaSoporteTecnico === informacionSolicitud.idVinculada)
          {

            if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
            {
              const date = new Date(data['fechaSolicitud'])

              //Agregar nueva solicitud finalizada
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setNuevasSolicitudesFinalizadas', data)
              console.log(data)

              //Cambiar estatus de solicitudes finalizadas
              commit('modificarSolicitudesFinalizadas', {id: data.idSolicitud, estatus: estatusSolicitud, estatusNuevoSolicitud: data.estatusNuevoSolicitud })
            }

          }

          if(informacionSolicitud.tipoSolicitud === 'en curso' && state.idVinculadaSoporteTecnico === informacionSolicitud.idVinculada)
          {

            if (estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusSolicitud ===  'recolectando')
            {
              const date = new Date(data['fechaSolicitud'])

              //Agregar nueva solicitud en curso
              data.idSolicitudCompleta = data.idSolicitud
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setNuevasSolicitudesEnCurso', data)
              console.log(data)

              //Cambiar estatus de solicitudes en curso
              commit('modificarSolicitudesEnCurso', {id: data.idSolicitud, estatus: estatusSolicitud, vehiculoRepartidor: data['vehiculoRepartidor'], nombreRepartidor: data['nombreRepartidor'], estatusNuevoSolicitud: data.estatusNuevoSolicitud })
            }
          }

          if(informacionSolicitud.tipoSolicitud === 'programado' && state.idVinculadaSoporteTecnico === informacionSolicitud.idVinculada)
          {
            if (estatusSolicitud === 'programado')
            {
              const date = new Date(data['fechaSolicitud'])

              //Agregar nueva solicitud programada
              data.idSolicitudCompleta = data.idSolicitud
              data.fechaSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
              commit('setNuevasSolicitudesProgramadas', data)
              console.log(data)

              //Cambiar estatus de solicitudes programadas
              commit('modificarSolicitudesProgramadas', {id: data.idSolicitud, estatus: estatusSolicitud, estatusNuevoSolicitud: data.estatusNuevoSolicitud })
            }
          }

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getSolicitudesEntregadasRepartidor({ commit, dispatch }, informacionRepartidor)
    {
      try
      {
        console.log("HELLO");
        dispatch('activarDesactivarLoader',true);
        const db = firebase.database()

        commit('setReiniciarSolicitudesEntregadasRepartidor')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        referencia.orderByChild('idRepartidor_Timestamp')
        .startAt(informacionRepartidor.idRepartidor + '_' + informacionRepartidor.fechaInicio)
        .endAt(informacionRepartidor.idRepartidor + '_' + informacionRepartidor.fechaFinal)
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          const date = new Date(data['fechaSolicitud'])

          data.idSolicitudCompleta = data.idSolicitud
          data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
          data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()

          console.log(data)

          commit('setSolicitudesEntregadasRepartidor', data)
          dispatch('activarDesactivarLoader',false);

        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    setNotaSolicitud({ commit }, informacionSolicitud)
    {
      try
      {
        console.log(informacionSolicitud)

        const db = firebase.database()

        /* //////  ////// */
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/' + informacionSolicitud.idSolicitud)
        .update({ notaAdicional: informacionSolicitud.nota })

        const alerta =
        {
          status: true,
          titulo: 'Guardado',
          mensaje: 'La información de la solicitud ha sido guardada.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-center'
        }

        commit('setMensajeAlerta', alerta)
      }
    },

    //////////////////////////////////////////////////////////////
    getTotalSolicitudesAdministrador({ commit })
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        commit('setReiniciarTotalSolicitudes')

        if(tipoUsuario === 'Admin')
        {

          referencia.orderByChild('fechaSolicitud')
          .startAt(fechaInicio)
          .endAt(timeStampFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            for (const id in data['solicitud entrega'])
            {
              if(data['solicitud entrega'][id].estatusNuevoSolicitud !== undefined)
              {

                const estatusNuevo = data['solicitud entrega'][id].estatusNuevoSolicitud

                if (estatusNuevo === 'Programado')
                {
                  data.idSolicitudCompleta = data.idSolicitud
                  commit('setTotalSolicitudesProgramadas', { ...data['solicitud entrega'][id], ...data})
                }

                if (estatusNuevo == 'En establecimiento' || estatusNuevo === 'Recogido' || estatusNuevo === 'Aceptado' ||
                estatusNuevo === 'En curso' || estatusNuevo ===  'Conectando' || estatusNuevo ===  'Recolectando' ||
                estatusNuevo === 'Buscando' || estatusNuevo === 'En punto de entrega' || estatusNuevo === 'Notificado')
                {
                  data.idSolicitudCompleta = data.idSolicitud
                  commit('setTotalSolicitudesEnCurso', { ...data['solicitud entrega'][id], ...data})
                }

              }
            }

          })

        }
        else
        {

          //este modifica la tabla de lideres y soporte tecnico
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()
            // console.log('1')
            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
            const estatusNuevoSolicitud = data['estatusNuevoSolicitud']

            if (estatusSolicitud === 'programado')
            {
              data.idSolicitudCompleta = data.idSolicitud
              // commit('setTotalSolicitudesProgramadas', { ...data['solicitud entrega'][id], ...data})
              commit('setTotalSolicitudesEnCurso', {  ...data})
            }

            if (estatusSolicitud == 'en establecimiento'|| estatusNuevoSolicitud === 'recogido' || estatusNuevoSolicitud === 'en curso' ||
            estatusNuevoSolicitud === 'en establecimiento' || estatusSolicitud === 'aceptado' ||
            estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusNuevoSolicitud === 'notificado'
            || estatusSolicitud ===  'recolectando' || estatusNuevoSolicitud === 'en punto de entrega' || estatusNuevoSolicitud === 'buscando')
            {
              data.idSolicitudCompleta = data.idSolicitud
              // console.log(data)
              // commit('setTotalSolicitudesEnCurso', { ...data['solicitud entrega'][id], ...data})
              commit('setTotalSolicitudesEnCurso', {  ...data})
            }

          })

        }

      }
      catch (error)
      {
        throw error
      }
    },
    //////////////////////////////////////////////////////////////
    getTotalNuevasSolicitudesAdministrador({ commit })
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {

          referencia.orderByChild('fechaSolicitud')
          .startAt(fechaInicio)
          .endAt(timeStampFinal)
          .on('child_changed', function (snapshot)
          {
            const data = snapshot.val()

            for (const id in data['solicitud entrega'])
            {
              if(data['solicitud entrega'][id].estatusNuevoSolicitud !== undefined)
              {

                const estatusNuevo = data['solicitud entrega'][id].estatusNuevoSolicitud

                if (estatusNuevo === 'Entregado' || estatusNuevo === 'Cancelado')
                {
                  data.idSolicitudCompleta = data.idSolicitud
                  commit('setTotalNuevasSolicitudesFinalizadas', { ...data, ...data['solicitud entrega'][id], id: data['solicitud entrega'][id].idEntrega })
                }

                if (estatusNuevo === 'Programado')
                {
                  data.idSolicitudCompleta = data.idSolicitud
                  commit('setTotalNuevasSolicitudesProgramadas', { ...data, ...data['solicitud entrega'][id] })
                }

                if (estatusNuevo == 'En establecimiento' || estatusNuevo === 'Recogido' || estatusNuevo === 'Aceptado' ||
                estatusNuevo === 'En curso' || estatusNuevo ===  'Conectando' || estatusNuevo ===  'Recolectando' ||
                estatusNuevo === 'Buscando' || estatusNuevo === 'En punto de entrega' || estatusNuevo === 'Notificado')
                {
                  data.idSolicitudCompleta = data.idSolicitud
                  commit('setTotalNuevasSolicitudesEnCurso', { ...data, ...data['solicitud entrega'][id] })
                }

              }
            }

          })

        }
        else
        {

          //este modifica la tabla de lideres y soporte tecnico
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_changed', function (snapshot)
          {
            const data = snapshot.val()
            console.log('2')
            const estatusSolicitud = data['estatusSolicitud'].toLowerCase()
            const estatusNuevoSolicitud = data['estatusNuevoSolicitud'].toLowerCase()

            if (estatusSolicitud === 'entregado' || estatusSolicitud === 'cancelado')
            {
              data.idSolicitudCompleta = data.idSolicitud
              commit('setTotalNuevasSolicitudesFinalizadas', { ...data, ...data['solicitud entrega'][id], id: data['solicitud entrega'][id].idEntrega })
            }

            if (estatusSolicitud === 'programado')
            {
              data.idSolicitudCompleta = data.idSolicitud
              commit('setTotalNuevasSolicitudesProgramadas', { ...data, ...data['solicitud entrega'][id] })
            }

            if (estatusSolicitud == 'en establecimiento'|| estatusNuevoSolicitud === 'recogido' || estatusNuevoSolicitud === 'en curso' ||
            estatusNuevoSolicitud === 'en establecimiento' || estatusSolicitud === 'aceptado' ||
            estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusNuevoSolicitud === 'notificado'
            || estatusSolicitud ===  'recolectando' || estatusNuevoSolicitud === 'en punto de entrega' || estatusNuevoSolicitud === 'buscando')
            {
              data.idSolicitudCompleta = data.idSolicitud
              commit('setTotalNuevasSolicitudesEnCurso', { ...data, ...data['solicitud entrega'][id] })
            }

          })

        }

      }
      catch (error)
      {
        throw error
      }
    },

  },

}