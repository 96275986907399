import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube' 
import VueCompositionAPI from '@vue/composition-api'
import VueTelInput from 'vue-tel-input'
//import 'vue-tel-input/dist/vue-tel-input.css'

import vco from 'v-click-outside'

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import '@/assets/scss/app.scss'

import { initFirebaseBackend } from './helpers/firebase/authUtils'

//Configuracion de Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
}
// console.log(process.env.VUE_APP_PROJECTID)
//Iniciar Firebase
initFirebaseBackend(firebaseConfig)

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VueMask)
Vue.use(VueTelInput)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_APIKEY,
    libraries: ["places"],
    region: 'ES',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.use(VueCompositionAPI)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
