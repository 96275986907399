<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

import { mapActions } from "vuex";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    ...mapActions("solicitudesAdministrador", [
      "getTotalNuevasSolicitudesAdministrador",
    ]),
  },
  computed: {
    validarUsuario() {
      const usuarioAutentificado = JSON.parse(
        localStorage.getItem("datosUsuario")
      );

      if (usuarioAutentificado === null) {
        return false;
      }

      return usuarioAutentificado.usuario === "Admin" ||
        usuarioAutentificado.usuario === "Lider" ||
        usuarioAutentificado.usuario === "soporte"
        ? true
        : false;
    },
  },
  mounted() {
    if (this.validarUsuario) {
      this.getTotalNuevasSolicitudesAdministrador();
    }
  },
};
</script>
<style >
body {
  font-family: "Comfortaa", sans-serif !important;
}
</style>