import Vue from 'vue'
import Vuex from 'vuex'

import cloneDeep from 'lodash/cloneDeep'

import modules from './modules'

import cuentaUsuarios from './jat/usuarios/cuentaUsuarios'
import directorioUsuarios from './jat/usuarios/directorioUsuarios'
import facturacionUsuarios from './jat/usuarios/facturacionUsuarios'
import solicitudesUsuarios from './jat/usuarios/solicitudesUsuarios'
import repartidoresSolicitudes from './jat/usuarios/repartidoresSolicitudes'

import cuentaAdministrador from './jat/administradores/cuentaAdministrador'
import solicitudesAdministrador from './jat/administradores/solicitudesAdministrador'
import configuracionesAdministrador from './jat/administradores/configuracionesAdministrador'
import reporteAdministrador from './jat/administradores/reporteAdministrador'
import preguntasFrecuentesAdministrador from './jat/administradores/preguntasFrecuentesAdministrador'
import notificacionesUnoUno from './jat/administradores/notificacionesUnoUno'
import repartidoresConectados from './jat/administradores/repartidoresConectados'
import soporteTecnico from './jat/administradores/soporteTecnico'
import direccionesClientes from './jat/administradores/direccionesClientes'

//import { getDistancia } from '../composables/getDistancia'
import { getDistancia } from '../composables/distanciaOsrm'
import { useImagen } from '../composables/setImagen'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules:
  {
    ...modules,
    cuentaUsuarios,
    directorioUsuarios,
    facturacionUsuarios,
    solicitudesUsuarios,
    repartidoresSolicitudes,

    cuentaAdministrador,
    solicitudesAdministrador,
    configuracionesAdministrador,
    reporteAdministrador,
    preguntasFrecuentesAdministrador,
    notificacionesUnoUno,
    repartidoresConectados,
    soporteTecnico,
    direccionesClientes,
  },
  state:
  {
    listadoIdEntrega: [],
    solicitudesReporte: [],
    poligonosSolicitudes: [],
    detallesTiempoEstimado: {},
    usuario: {},
    estatusSolicitud: '',
    detallesSolicitud: {},
    repartidoresNotificados: [],
    fechaFiltradaSolicitudes:[],
    detallesEntrega: {},
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations:
  {
    setDetallesEntrega(state, entrega)
    {
      state.detallesEntrega = entrega
    },
    setFechaFiltradaSolicitudes(state, fecha)
    {
      state.fechaFiltradaSolicitudes = fecha
    },
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setReprogramarSolicitudState(state, solicitud)
    {
      if(state.detallesSolicitud.idSolicitud === solicitud.idSolicitud)
      {
        state.detallesSolicitud.estatusSolicitud = solicitud.estatusSolicitud.toLowerCase()
        state.detallesSolicitud.fechaRecoleccion = solicitud.fechaRecoleccion

      }
    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
    setDetallesSolicitud(state, solicitud)
    {
      state.detallesSolicitud = solicitud
    },
    modificarSolicitud(state, solicitud)
    {

      if(state.detallesSolicitud.idSolicitud === solicitud.idSolicitud)
      {
        state.detallesSolicitud.estatusSolicitud = solicitud.estatusSolicitud.toLowerCase()
        state.detallesSolicitud.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
        state.detallesSolicitud.notaAdicional = solicitud.notaAdicional

        if(solicitud.estatusSolicitud.toLowerCase() !== 'cancelado' && solicitud.estatusSolicitud.toLowerCase() !== 'conectando')
        {
          state.detallesSolicitud.nombreRepartidor = solicitud.nombreRepartidor
          state.detallesSolicitud.fotoRepartidor = solicitud.fotoRepartidor
          state.detallesSolicitud.vehiculoRepartidor = solicitud.vehiculoRepartidor
          state.detallesSolicitud.fechaConcluido = solicitud.fechaConcluido
          state.detallesSolicitud.fechaRecogida = solicitud.fechaRecogida
          state.detallesSolicitud.fotoRecoleccion = solicitud.fotoRecoleccion
          state.detallesSolicitud.fechaAceptado = solicitud.fechaAceptado
          state.detallesSolicitud.fechaEnCurso = solicitud.fechaEnCurso
          state.detallesSolicitud.fechaEnEstablecimiento = solicitud.fechaEnEstablecimiento
          state.detallesSolicitud.fechaPaquetesRecogidos = solicitud.fechaPaquetesRecogidos
          state.detallesSolicitud.fotoEntrega = solicitud.fotoEntrega
          state.detallesSolicitud.fechaEnPuntoDeEntrega = solicitud.fechaEnPuntoDeEntrega
          //state.detallesSolicitud.fechaRecoleccion = solicitud.fechaRecoleccion
          //state.detallesSolicitud.fechaSolicitud = solicitud.fechaSolicitud

        }

        if(solicitud.estatusSolicitud.toLowerCase() === 'cancelado')
        {
          state.detallesSolicitud.fechaConcluido = solicitud.fechaConcluido
        }

        state.detallesSolicitud['solicitud entrega'] = solicitud['solicitud entrega']
      }

    },
    finalizarSolicitud(state, solicitudFinalizada)
    {

      const formatearFecha = (fecha) => {
        const fechaSeleccionada = new Date(fecha)
        const fechaFormateada = fechaSeleccionada.getDate() + "/" + (fechaSeleccionada.getMonth() + 1) + "/" + fechaSeleccionada.getFullYear() + "  a las " + fechaSeleccionada.getHours() + ":" + fechaSeleccionada.getMinutes() + ":" + fechaSeleccionada.getSeconds()
        return fechaFormateada
      }

      const informacionRecoleccion = {
        fechaAceptado: formatearFecha(solicitudFinalizada.fechaAceptado),
        fechaEnCurso: formatearFecha(solicitudFinalizada.fechaEnCurso),
        fechaEnEstablecimiento: formatearFecha(solicitudFinalizada.fechaEnEstablecimiento),
        fechaPaquetesRecogidos: formatearFecha(solicitudFinalizada.fechaPaquetesRecogidos),
        fechaRecogida: formatearFecha(solicitudFinalizada.fechaRecogido),
        fechaConcluido: formatearFecha(solicitudFinalizada.fechaEnEstablecimiento),
        fechaSolicitado: formatearFecha(solicitudFinalizada.fechaSolicitado),
        fotoRecoleccion: solicitudFinalizada.fotoRecoleccion,
        idVinculada_Timestamp: solicitudFinalizada.idVinculada_Timestamp,
      }

      if(state.detallesSolicitud.idSolicitud === solicitudFinalizada.idSolicitud)
      {
        state.detallesSolicitud.estatusSolicitud = 'finalizado'

        state.detallesSolicitud = { ...state.detallesSolicitud, ...solicitudFinalizada.informacionRepartidor, ...informacionRecoleccion }

        for(const entrega of solicitudFinalizada.informacionEntrega)
        {
          const idEntrega = entrega.idEntrega

          state.detallesSolicitud['solicitud entrega'][idEntrega]['fechaEnCurso'] = entrega['fechaEnCurso']
          state.detallesSolicitud['solicitud entrega'][idEntrega]['Time recogido'] = entrega['Time recogido']
          state.detallesSolicitud['solicitud entrega'][idEntrega]['fechaConcluido'] = entrega['fechaConcluido']
          state.detallesSolicitud['solicitud entrega'][idEntrega]['fotoEntrega'] = entrega['fotoEntrega']
          state.detallesSolicitud['solicitud entrega'][idEntrega]['estatusEntrega'] = 'finalizado'
        }

      }
    },
    setTiemposEstimados(state, tiemposEstimados)
    {
      state.detallesTiempoEstimado = tiemposEstimados
    },
    setPoligonosSolicitudes(state, poligono)
    {
      state.poligonosSolicitudes = poligono
    },
    setListadoIdEntrega(state, idEntrega)
    {
      state.listadoIdEntrega = idEntrega
    },
    setRepartidoresNotificados(state, repartidor)
    {
      console.log("///////")
      console.log(repartidor)
      state.repartidoresNotificados.push(repartidor)
    }

  },
  actions:
  {
    //////////////////////////////////////////////////////////////
    getDetallesSolicitudes({ commit }, idEntrega)
    {
      try
      {
        commit('setEstatusSolicitud', 'cargando')

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        const arrayIdEntrega = []

        if(tipoUsuario === 'Admin')
        {

          referencia
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            //Listado de ID de entrega
            arrayIdEntrega.push(data.idSolicitud)

            //console.log(data['solicitud entrega'][id].idEntrega)
            if(data.idSolicitud === idEntrega)
            {


              const date = new Date(data.fechaSolicitud)
              const dateProgramado = new Date(data.fechaRecoleccion)
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])

              console.log(snapshot.key)

              data.timeStampFechaRecoleccion = data.fechaRecoleccion
              data.idSolicitud = snapshot.key
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaRecoleccion = dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes() //+ ":" + dateProgramado.getSeconds()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
              data.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              data.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()

              commit('setDetallesSolicitud', data)
            }

          })

        }

        if(tipoUsuario === 'Lider' || tipoUsuario === 'soporte')
        {
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            //Listado de ID de entrega
            arrayIdEntrega.push(data.idSolicitud)

            //console.log(data['solicitud entrega'][id].idEntrega)
            if(data.idSolicitud === idEntrega)
            {


              const date = new Date(data.fechaSolicitud)
              const dateProgramado = new Date(data.fechaRecoleccion)
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])

              console.log(snapshot.key)

              data.timeStampFechaRecoleccion = data.fechaRecoleccion
              data.idSolicitud = snapshot.key
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaRecoleccion = dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes() //+ ":" + dateProgramado.getSeconds()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
              data.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              data.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
              commit('setDetallesSolicitud', data)
            }

          })
        }

        if(tipoUsuario !== 'Lider' && tipoUsuario !== 'soporte' && tipoUsuario !== 'Admin')
        {
          referencia.orderByChild('clienteID')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            //Listado de ID de entrega
            arrayIdEntrega.push(data.idSolicitud)

            //console.log(data['solicitud entrega'][id].idEntrega)
            if(data.idSolicitud === idEntrega)
            {


              const date = new Date(data.fechaSolicitud)
              const dateProgramado = new Date(data.fechaRecoleccion)
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])

              // console.log(snapshot.key)

              data.timeStampFechaRecoleccion = data.fechaRecoleccion
              data.idSolicitud = snapshot.key
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaRecoleccion = dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes() //+ ":" + dateProgramado.getSeconds()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
              data.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              data.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
              commit('setDetallesSolicitud', data)
            }

          })
        }

        commit('setListadoIdEntrega', arrayIdEntrega)

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }

    },
    ////////////////////////////////////////////////////////////// (Reprogramar)
    modificarEstatusSolicitud({ commit }, solicitud)
    {
      try
      {

        console.log(solicitud)

        const db = firebase.database()

        const nuevoEstatus = {
          estatusSolicitud: solicitud.estatus
        }

        /* ////// Actualizar estatus de la solicitud ////// */
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}`)
        .update(nuevoEstatus)

        //Actualizar estatus en los puntos de entrega
        const referencia = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}/solicitud entrega`)

        referencia.orderByChild('idVinculada')
        .on('child_added', async function (snapshot)
        {

          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}/solicitud entrega/${snapshot.key}`)
          .update({ estatusEntrega: solicitud.estatus })

        })

        const alerta =
        {
          status: true,
          titulo: solicitud.estatus.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
          mensaje: solicitud.mensaje,
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        //commit('modificarSolicitud', solicitud)
        commit('setMensajeAlerta', alerta)

      } catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }

    },
    //////////////////////////////////////////////////////////////
    getTiemposEstimado({ commit }, uid)
    {

      try
      {

        commit('setEstatusSolicitud', 'cargando')

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Tiendas exxpres/' + uid)

        const datosTiempoEstimado = {}

        //.equalTo(uid)
        referencia.on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          datosTiempoEstimado[snapshot.key] = data

          commit('setTiemposEstimados', datosTiempoEstimado)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }
    },
    //////////////////////////////////////////////////////////////
    actualizarEstatusSolicitudes({ commit }, idEntrega)
    {

      try
      {
        console.log("Hola");
        const db = firebase.database()

        /*const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = datosUsuario.uid*/

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        const nuevoEstatus = {
          idSolicitud: '',
          estatusSolicitud: '',
          nombreRepartidor: '',
          fotoRepartidor: '',
          vehiculoRepartidor: '',
        }

        referencia.on('child_changed', function (snapshot)
        {
          const data = snapshot.val()
          console.log("cambiadooooooooooooo1")
          console.log(data);
          console.log(data.idSolicitud === idEntrega);
          //console.log(data['solicitud entrega'][id].idEntrega)
          if(data.idSolicitud === idEntrega)
          {
            console.log(data.estatusSolicitud)
            console.log(snapshot.key)

            nuevoEstatus.estatusSolicitud = data.estatusSolicitud
            nuevoEstatus.estatusNuevoSolicitud = data.estatusNuevoSolicitud
            nuevoEstatus.idSolicitud = snapshot.key
            nuevoEstatus.estatusNuevoSolicitud = data.estatusNuevoSolicitud
            nuevoEstatus.fechaAceptado = data.fechaAceptado
            nuevoEstatus.fechaConcluido = data.fechaConcluido
            nuevoEstatus.fechaEnCurso = data.fechaEnCurso
            nuevoEstatus.fechaEnEstablecimiento = data.fechaEnEstablecimiento
            nuevoEstatus.fechaPaquetesRecogidos = data.fechaPaquetesRecogidos

            if(data.estatusSolicitud.toLowerCase() !== 'cancelado' && data.estatusSolicitud.toLowerCase() !== 'conectando')
            {
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])
              //const fechaAceptada = new Date(data['fechaAceptado'])
              //const fechaEnCursa = new Date(data['fechaEnCurso'])
              //const fechaEnEstablecimienta = new Date(data['fechaEnEstablecimiento'])
              //const fechaEnPuntoDeEntrega = new Date(data['fechaEnPuntoDeEntrega'])

              nuevoEstatus.nombreRepartidor = data.nombreRepartidor
              nuevoEstatus.fotoRepartidor = data.fotoRepartidor
              nuevoEstatus.vehiculoRepartidor = data.vehiculoRepartidor
              nuevoEstatus.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              nuevoEstatus.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
              //nuevoEstatus.fechaAceptado = fechaAceptada.getDate() + "/" + (fechaAceptada.getMonth() + 1) + "/" + fechaAceptada.getFullYear() + "  a las " + fechaAceptada.getHours() + ":" + fechaAceptada.getMinutes() + ":" + fechaAceptada.getSeconds()
              //nuevoEstatus.fechaEnCurso = fechaEnCursa.getDate() + "/" + (fechaEnCursa.getMonth() + 1) + "/" + fechaEnCursa.getFullYear() + "  a las " + fechaEnCursa.getHours() + ":" + fechaEnCursa.getMinutes() + ":" + fechaEnCursa.getSeconds()
              //nuevoEstatus.fechaEnEstablecimiento = fechaEnEstablecimienta.getDate() + "/" + (fechaEnEstablecimienta.getMonth() + 1) + "/" + fechaEnEstablecimienta.getFullYear() + "  a las " + fechaEnEstablecimienta.getHours() + ":" + fechaEnEstablecimienta.getMinutes() + ":" + fechaEnEstablecimienta.getSeconds()
              nuevoEstatus.fotoRecoleccion = data.fotoRecoleccion
              nuevoEstatus.fotoEntrega = data.fotoEntrega
              //nuevoEstatus.fechaEnPuntoDeEntrega = fechaEnPuntoDeEntrega.getDate() + "/" + (fechaEnPuntoDeEntrega.getMonth() + 1) + "/" + fechaEnPuntoDeEntrega.getFullYear() + "  a las " + fechaEnPuntoDeEntrega.getHours() + ":" + fechaEnPuntoDeEntrega.getMinutes() + ":" + fechaEnPuntoDeEntrega.getSeconds()
            }

            if(data.estatusSolicitud.toLowerCase() === 'cancelado')
            {
              const fechaFinalizado = new Date(data['fechaConcluido'])

              nuevoEstatus.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
            }

            nuevoEstatus['solicitud entrega'] = data['solicitud entrega']

          }

          commit('modificarSolicitud', nuevoEstatus)

        })

      }
      catch (error)
      {
        alert(error)
      }

    },
    /* ---------------------------------------------------------------------- */
    //////////////////////////////////////////////////////////////
    getDetallesSolicitudesAdministrador({ commit }, idEntrega)
    {
      console.log(idEntrega)
      try
      {

        commit('setEstatusSolicitud', 'cargando')

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        const arrayIdEntrega = []

        if(tipoUsuario === 'Admin')
        {

          referencia.on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            //Listado de ID de entrega
            arrayIdEntrega.push(data.idSolicitud)

            //console.log(data['solicitud entrega'][id].idEntrega)
            if(data.idSolicitud === idEntrega)
            {

              const date = new Date(data.fechaSolicitud)
              const dateProgramado = new Date(data.fechaRecoleccion)
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])

              data.timestampSolicitud = data['fechaSolicitud']

              console.log(snapshot.key)

              data.timeStampFechaRecoleccion = data.fechaRecoleccion
              data.idSolicitud = snapshot.key
              data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
              data.fechaRecoleccion = dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes() //+ ":" + dateProgramado.getSeconds()
              data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
              data.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              data.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
              commit('setDetallesSolicitud', data)
            }

          })
      }
      else
      {

        referencia.orderByChild('idVinculada')
        .equalTo(uid)
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          //Listado de ID de entrega
          arrayIdEntrega.push(data.idSolicitud)

          //console.log(data['solicitud entrega'][id].idEntrega)
          if(data.idSolicitud === idEntrega)
          {

            const date = new Date(data.fechaSolicitud)
            const dateProgramado = new Date(data.fechaRecoleccion)
            const fechaFinalizado = new Date(data['fechaConcluido'])
            const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])

            data.timestampSolicitud = data['fechaSolicitud']

            console.log(snapshot.key)

            data.timeStampFechaRecoleccion = data.fechaRecoleccion
            data.idSolicitud = snapshot.key
            data.estatusSolicitud = data.estatusSolicitud.toLowerCase()
            data.fechaRecoleccion = dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes() //+ ":" + dateProgramado.getSeconds()
            data.fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
            data.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
            data.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
            commit('setDetallesSolicitud', data)
          }

        })
      }

        commit('setListadoIdEntrega', arrayIdEntrega)

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }

    },
    //////////////////////////////////////////////////////////////
    actualizarEstatusSolicitudesAdministrador({ commit }, idEntrega)
    {

      try
      {

        const db = firebase.database()
        /*const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = datosUsuario.uid*/

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        const nuevoEstatus = {
          idSolicitud: '',
          estatusSolicitud: '',
          nombreRepartidor: '',
          fotoRepartidor: '',
          vehiculoRepartidor: '',
        }

        referencia.on('child_changed', function (snapshot)
        {
          const data = snapshot.val()
          console.log("cambiadooooooooooooo")

          //console.log(data['solicitud entrega'][id].idEntrega)
          if(data.idSolicitud === idEntrega)
          {
            console.log(data.estatusSolicitud)
            console.log(snapshot.key)

            nuevoEstatus.estatusSolicitud = data.estatusSolicitud
            nuevoEstatus.estatusNuevoSolicitud = data.estatusNuevoSolicitud
            nuevoEstatus.idSolicitud = snapshot.key
            nuevoEstatus.estatusNuevoSolicitud = data.estatusNuevoSolicitud
            nuevoEstatus.fechaAceptado = data.fechaAceptado
            nuevoEstatus.fechaEnCurso = data.fechaEnCurso
            nuevoEstatus.fechaEnEstablecimiento = data.fechaEnEstablecimiento

            if(data.estatusSolicitud.toLowerCase() !== 'cancelado' && data.estatusSolicitud.toLowerCase() !== 'conectando')
            {
              const fechaFinalizado = new Date(data['fechaConcluido'])
              const fechaRecogida = new Date(data['fechaPaquetesRecogidos'])
              const fechaAceptada = new Date(data['fechaAceptado'])
              const fechaEnCursa = new Date(data['fechaEnCurso'])
              const fechaEnEstablecimienta = new Date(data['fechaEnEstablecimiento'])
              const fechaEnPuntoDeEntrega = new Date(data['fechaEnPuntoDeEntrega'])

              data.timestampSolicitud = data['fechaSolicitud']

              nuevoEstatus.nombreRepartidor = data.nombreRepartidor
              nuevoEstatus.fotoRepartidor = data.fotoRepartidor
              nuevoEstatus.vehiculoRepartidor = data.vehiculoRepartidor
              nuevoEstatus.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
              nuevoEstatus.fechaRecogida = fechaRecogida.getDate() + "/" + (fechaRecogida.getMonth() + 1) + "/" + fechaRecogida.getFullYear() + "  a las " + fechaRecogida.getHours() + ":" + fechaRecogida.getMinutes() + ":" + fechaRecogida.getSeconds()
              nuevoEstatus.fechaAceptado = fechaAceptada.getDate() + "/" + (fechaAceptada.getMonth() + 1) + "/" + fechaAceptada.getFullYear() + "  a las " + fechaAceptada.getHours() + ":" + fechaAceptada.getMinutes() + ":" + fechaAceptada.getSeconds()
              nuevoEstatus.fechaEnCurso = fechaEnCursa.getDate() + "/" + (fechaEnCursa.getMonth() + 1) + "/" + fechaEnCursa.getFullYear() + "  a las " + fechaEnCursa.getHours() + ":" + fechaEnCursa.getMinutes() + ":" + fechaEnCursa.getSeconds()
              nuevoEstatus.fechaEnEstablecimiento = fechaEnEstablecimienta.getDate() + "/" + (fechaEnEstablecimienta.getMonth() + 1) + "/" + fechaEnEstablecimienta.getFullYear() + "  a las " + fechaEnEstablecimienta.getHours() + ":" + fechaEnEstablecimienta.getMinutes() + ":" + fechaEnEstablecimienta.getSeconds()
              nuevoEstatus.fotoRecoleccion = data.fotoRecoleccion
              nuevoEstatus.fotoEntrega = data.fotoEntrega
              nuevoEstatus.notaAdicional = data.notaAdicional
              nuevoEstatus.fechaEnPuntoDeEntrega = fechaEnPuntoDeEntrega.getDate() + "/" + (fechaEnPuntoDeEntrega.getMonth() + 1) + "/" + fechaEnPuntoDeEntrega.getFullYear() + "  a las " + fechaEnPuntoDeEntrega.getHours() + ":" + fechaEnPuntoDeEntrega.getMinutes() + ":" + fechaEnPuntoDeEntrega.getSeconds()
            }

            if(data.estatusSolicitud.toLowerCase() === 'cancelado')
            {
              const fechaFinalizado = new Date(data['fechaConcluido'])

              nuevoEstatus.fechaConcluido = fechaFinalizado.getDate() + "/" + (fechaFinalizado.getMonth() + 1) + "/" + fechaFinalizado.getFullYear() + "  a las " + fechaFinalizado.getHours() + ":" + fechaFinalizado.getMinutes() + ":" + fechaFinalizado.getSeconds()
            }

            nuevoEstatus['solicitud entrega'] = data['solicitud entrega']

          }

          commit('modificarSolicitud', nuevoEstatus)

        })

      }
      catch (error)
      {
        alert(error)
      }

    },
    //////////////////////////////////////////////////////////////
    setReprogramarSolicitud({ commit }, solicitud)
    {
      try
      {

        console.log(solicitud)

        const db = firebase.database()
        const idSolicitud = solicitud.idSolicitud

        delete solicitud.idSolicitud

        /* ////// Actualizar estatus en Firebase ////// */
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/' + idSolicitud)
        .update(solicitud)

        //
        const dateProgramado = new Date(solicitud.fechaRecoleccion)

        const stateReprogramado = {
          fechaRecoleccion: dateProgramado.getDate() + "/" + (dateProgramado.getMonth() + 1) + "/" + dateProgramado.getFullYear() + " a las " + dateProgramado.getHours() + ":" + dateProgramado.getMinutes(),
          estatusSolicitud: solicitud.estatusSolicitud,
          idSolicitud: idSolicitud,
        }

        commit('setReprogramarSolicitudState', stateReprogramado)

        //
        const alerta =
        {
          status: true,
          titulo: 'Solicitud reprogramada',
          mensaje: 'La solicitud ha sido reprogramada para el ' + stateReprogramado.fechaRecoleccion,
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        //commit('modificarSolicitud', solicitud)
        commit('setMensajeAlerta', alerta)

      } catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }

    },
    //////////////////////////////////////////////////////////////
    getPoligonosRecoleccion({ commit })
    {

        try
        {

          commit('setEstatusSolicitud', 'cargando')

          const db = firebase.database()

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en poligonos')

            /* ////// Obtener listado de direcciones ////// */
            referencia.once('value', function(snapshot)
            {
              const data = snapshot.val()

              const arrayDatos = []
              let objetoPoligono = {}

              console.log(data)

              for (let id in data)
              {
                objetoPoligono = {}

                objetoPoligono.idPoligono = id
                objetoPoligono.nombrePoligono = data[id].Nombre
                objetoPoligono.latitudePoligono = data[id]['latitude']
                objetoPoligono.longitudePoligono = data[id]['longitude']
                objetoPoligono.poligono = data[id].Poligono.coordinates.filter(Boolean)
                objetoPoligono.direccion = data[id].direccion

                arrayDatos.push(objetoPoligono)
              }

              console.log(arrayDatos)
              commit('setPoligonosSolicitudes', arrayDatos)

            })
        }
        catch(error)
        {
          commit('setEstatusSolicitud', 'error')
          throw error
        }
        finally
        {
          commit('setEstatusSolicitud', 'finalizado')
        }
    },
    //////////////////////////////////////////////////////////////
    setNotificarOportunidades({ state, commit }, solicitud)
    {
      try
      {
        console.log(solicitud)
        const db = firebase.database()

        //Verificar que haya repartidores conectados
        if(state.repartidoresNotificados.length)
        {

          //Clone de los repartidores conectados
          const cloneRepartidores = cloneDeep(state.repartidoresNotificados)

          //Ordenar a los repartidores por su distancia
          const repartidoresOrdenados = cloneRepartidores.sort((repartidorA, repartidorB) => parseFloat(repartidorA['Distancia']) - parseFloat(repartidorB['Distancia']))
          console.log(repartidoresOrdenados[0])
          console.log('&&&&&')
          console.log(repartidoresOrdenados)
          //
          repartidoresOrdenados[0]['Notificado'] = 'Si'

          //Recorrer a los repartidores conectados
          for(const repartidor of repartidoresOrdenados)
          {
            console.log("--")
            console.log(repartidor)
            console.log("--")

            //Guardar oportunidades de entrega a repartidor notificado
            if(repartidor['Notificado'] === 'Si')
            {

              const informacionOportunidadEntrega = {
                ['OnTrack']: 'si',
                ['id orden']: solicitud.id,
                ['timestamp']: +new Date,
                ['anterior']: "null",
                ['siguiente']: "null",
              }

              console.log("+++++++++")
              console.log(informacionOportunidadEntrega)
              console.log("+++++++++")

              //Guardar oportunidad de entrega de repartidor notificado
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
              .set(informacionOportunidadEntrega)

              //Actualizar estatus de repartidores a ocupado
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${repartidor['Id repartidor']}`)
              .update({ ["EN LINEA"]: "SI", ["OCUPADO"]: "SI" })

              const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades`)

              //Obtener las oportunidades de entrega
              referenciaOportunidades.on('child_added', async function (snapshotOportunidades)
              {
                const dataOportunidades = await snapshotOportunidades.val()

                //El primero en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null' && snapshotOportunidades.key !== solicitud.id)
                {
                  //alert("1 - " + dataOportunidades['id orden'] + " // " + solicitud.id + " . // " + snapshotOportunidades.key)

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({ ['anterior']: "null", ['siguiente']: solicitud.id })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                  //Modificar el primero en la cola - solicitud actual
                  /*db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: "null", ['siguiente']: dataOportunidades['id orden'] })

                  //Modificar la cola de la solicitud nueva
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({ ['anterior']: solicitud.id, ['siguiente']: "null" }) */

                  /*db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })*/

                }

                //El ultimo en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'] != solicitud.id)
                {

                  //alert("2")

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({['anterior']: dataOportunidades['anterior'], ['siguiente']: solicitud.id })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                  //Modificar el primero en la cola
                  /*db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['anterior']}`)
                  .update({ ['siguiente']: dataOportunidades['id orden'] })*/

                  //Modificar el ultimo en la cola
                  /*db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({ ['siguiente']: solicitud.id })*/

                  //Modificar la cola de la solicitud nueva - solicitud actual
                  /*db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: solicitud.id, ['siguiente']: "null" })*/

                }

              })

            }

            //Guardar notificacion uno a uno
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${repartidor['Id repartidor']}`)
            .set(repartidor)

          }

        }

        /* -------------------------------------------------------- */
        //Verificar si no hay repartidor conectado
        //Notificar a todos los repartidores
        if(!state.repartidoresNotificados.length)
        {

          const referenciaSolicitudRepartidor = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

          ///
          referenciaSolicitudRepartidor.on('child_added', function (snapshotSolicitudRepartidor)
          {
            const dataSolicitudRepartior = snapshotSolicitudRepartidor.val()

            //&& dataSolicitudRepartior['Vehiculo'] === solicitud.vehiculo
            if(dataSolicitudRepartior['id vinculado'] === solicitud.idVinculada && dataSolicitudRepartior['Status'] === 'Aprobado' && dataSolicitudRepartior['Vehiculo'] !== undefined)
            {

              const idRepartidor = snapshotSolicitudRepartidor.key

              const informacionRepartidor = {
                ['Correo']: dataSolicitudRepartior['Correo'],
                //['Distancia']: distanciaRepartidor,
                ['Id repartidor']: idRepartidor,
                //['Latitud']: latRepartidor,
                //['Longitud']: lngRepartidor,
                ['Nombre']: dataSolicitudRepartior['Nombre'],
                ['Notificado']: 'Si',
                ['idVinculada']: dataSolicitudRepartior['id vinculado'],
                ['latitud-recoleccion']: solicitud.latOrigen,
                ['longitud-recoleccion']: solicitud.lngOrigen,
                ['vehiculoRepartidor']: dataSolicitudRepartior['Vehiculo'],
              }

              //Guardar notificacion uno a uno
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${idRepartidor}`)
              .set(informacionRepartidor)

              ////////////
              const informacionOportunidadEntrega = {
                ['OnTrack']: 'si',
                ['id orden']: solicitud.id,
                ['timestamp']: +new Date,
                ['anterior']: 'null',
                ['siguiente']: 'null',
              }

              //Guardar oportunidad de entrega de repartidor notificado
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
              .set(informacionOportunidadEntrega)

              const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades`)

              //Obtener las oportunidades de entrega
              referenciaOportunidades.on('child_added', function (snapshotOportunidades)
              {
                const dataOportunidades = snapshotOportunidades.val()

                //El primero en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null' && snapshotOportunidades.key !== solicitud.id) //&& ejecucionNueva
                {

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({ ['anterior']: "null", ['siguiente']: solicitud.id })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                }

                //El ultimo en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'] != solicitud.id) //&& ejecucionNueva
                {

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({['anterior']: dataOportunidades['anterior'], ['siguiente']: solicitud.id })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                }

              })


            }

          })

        }

        const detallesSolicitud = {
          ['Direccion-recoleccion']: solicitud.direccionRecoleccion,
          ['Hora']: solicitud.timestamp,
          ['Id solicitud']: solicitud.id,
          ['idVinculada']: solicitud.idVinculada,
          ['latitud-recoleccion']: solicitud.latOrigen,
          ['longitud-recoleccion']: solicitud.lngOrigen,
          ['vehiculoRepartidor']: solicitud.vehiculo,
        }

        //Guardar detalles de solicitud en notificacion uno a uno
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}`)
        .update(detallesSolicitud)

      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    setNotificarRepartidores({ commit }, solicitud)
    {
      try
      {

        const db = firebase.database()
        console.log(solicitud)
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups/Repartidores')

        //const repartidoresConectados = []

        //Obtener el geofire de repartidores
        referencia.on('child_added', function (geofireSnapshot)
        {

          const data = geofireSnapshot.val()

          const refRepartidoresSolicitud = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/' + geofireSnapshot.key)

          refRepartidoresSolicitud.on('value', async (repartidoresSolicitudSnapshot) => {

            const dataRepartidor = repartidoresSolicitudSnapshot.val()
            console.log('repartidoressss222')
            //Verificar si los repartidores estan en la misma zona que el lider
            if(data['data']['id vinculado'] === solicitud.idVinculada)
            {
              console.log('repartidoressss')
              //&& dataRepartidor['Vehiculo'] === solicitud.vehiculo && dataRepartidor['Status'] === 'Aprobado'
              if(dataRepartidor['OCUPADO'] === 'NO' && dataRepartidor['EN LINEA'] === 'SI')
              {
                console.log(dataRepartidor['OCUPADO'] + " - " + data['data']['Vehiculo'])

                const latRepartidor = data['l'][0]
                const lngRepartidor = data['l'][1]

                const { getDistanciaMapa } = getDistancia()

                const datosDistancia = {
                  latOrigen: solicitud.latOrigen,
                  lngOrigen: solicitud.lngOrigen,
                  latDestino: data['l'][0],
                  lngDestino: data['l'][1],
                }
                console.log(data['data']['Nombre'])
                console.log(datosDistancia)
                //Obtener la distancia entre el repartidor y el punto de recoleccion
                const distanciaObtenida = await getDistanciaMapa(datosDistancia)

                //Distancia del repartidor
                const distanciaRepartidor = (parseFloat(distanciaObtenida.routes[0].distance) / 1000).toFixed(2) //(distanciaObtenida.distancia * 1.60934).toFixed(2)

                if(distanciaRepartidor <= parseFloat(solicitud.distanciaCobertura))
                {

                  console.log(distanciaRepartidor)
                  console.log("####")

                  const informacionRepartidor = {
                    ['Correo']: data['data']['Correo'],
                    ['Distancia']: distanciaRepartidor,
                    ['Id repartidor']: geofireSnapshot.key,
                    ['Latitud']: latRepartidor,
                    ['Longitud']: lngRepartidor,
                    ['Nombre']: data['data']['Nombre'],
                    ['Notificado']: 'No',
                    ['idVinculada']: data['data']['id vinculado'],
                    ['latitud-recoleccion']: solicitud.latOrigen,
                    ['longitud-recoleccion']: solicitud.lngOrigen,
                    ['vehiculoRepartidor']: data['data']['Vehiculo'],
                  }

                  commit('setRepartidoresNotificados', informacionRepartidor)
                  //repartidoresConectados.push(informacionRepartidor)
                }

              }
            }
          })
        })

        //commit('setRepartidoresNotificados', repartidoresConectados)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    setReasignarNotificaciones({ state, commit }, solicitud)
    {
      try
      {

        const db = firebase.database()

        //Verificar que haya repartidores conectados
        if(state.repartidoresNotificados.length)
        {

          //Clone de los repartidores conectados
          const cloneRepartidores = cloneDeep(state.repartidoresNotificados)

          //Recorrer a los repartidores conectados
          for(const repartidor of cloneRepartidores)
          {
            //Guardar notificacion uno a uno
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${repartidor['Id repartidor']}`)
            .set(repartidor)
          }

        }

        ///Si no hay repartidores conectados
        if(!state.repartidoresNotificados.length)
        {

          const referenciaSolicitudRepartidor = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

          ///
          referenciaSolicitudRepartidor.on('child_added', function (snapshotSolicitudRepartidor)
          {
            const dataSolicitudRepartior = snapshotSolicitudRepartidor.val()

            //&& dataSolicitudRepartior['Vehiculo'] === solicitud.vehiculo
            if(dataSolicitudRepartior['id vinculado'] === solicitud.idVinculada && dataSolicitudRepartior['Status'] === 'Aprobado')
            {

              const idRepartidor = snapshotSolicitudRepartidor.key

              const informacionRepartidor = {
                ['Correo']: dataSolicitudRepartior['Correo'],
                //['Distancia']: distanciaRepartidor,
                ['Id repartidor']: idRepartidor,
                //['Latitud']: latRepartidor,
                //['Longitud']: lngRepartidor,
                ['Nombre']: dataSolicitudRepartior['Nombre'],
                ['Notificado']: 'No',
                ['idVinculada']: dataSolicitudRepartior['id vinculado'],
                ['latitud-recoleccion']: solicitud.latOrigen,
                ['longitud-recoleccion']: solicitud.lngOrigen,
                ['vehiculoRepartidor']: dataSolicitudRepartior['Vehiculo'],
              }

              //Guardar notificacion uno a uno
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${idRepartidor}`)
              .set(informacionRepartidor)

            }

          })

        }

        const detallesSolicitud = {
          ['Direccion-recoleccion']: solicitud.direccionRecoleccion,
          ['Hora']: solicitud.timestamp,
          ['Id solicitud']: solicitud.id,
          ['idVinculada']: solicitud.idVinculada,
          ['latitud-recoleccion']: solicitud.latOrigen,
          ['longitud-recoleccion']: solicitud.lngOrigen,
          ['vehiculoRepartidor']: solicitud.vehiculo,
        }

        //Guardar detalles de solicitud en notificacion uno a uno
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}`)
        .update(detallesSolicitud)

        const alerta =
        {
          status: true,
          titulo: 'Solicitud reasignada',
          mensaje: 'Se ha reasignado la solicitud a todos los repartidores.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    reiniciarDetallesSolicitud({ commit }, solicitud)
    {
      try
      {
        const db = firebase.database()

        const referenciaSolicitudes = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack`)

        referenciaSolicitudes.on('child_added', function (snapshotSolicitudes)
        {
          const data = snapshotSolicitudes.val()

          if(data['idSolicitud'] === solicitud.id)
          {

            //Eliminar pedidos tomado repartidor
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Pedido tomado repartidor/${data['idRepartidor']}/Repartidor/${solicitud.id}`).remove()

            //Actualizar custom data de repartidor
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/${data['idRepartidor']}/customData`)
            .update({ ['Pedido en curso OnTrack']: null })

            const nuevaInformacionSolicitud = {
              'fotoRepartidor': null,
              'idRepartidor': null,
              'idRepartidor_Timestamp': null,
              nombreRepartidor: null,
              vehiculoRepartidor: null,
              estatusSolicitud: 'Conectando',
              clienteID_Estatus: data['clienteID'] + '_Conectando',
              reasignado: 'si'
            }

            //Actualizar solicitud
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}`)
            .update(nuevaInformacionSolicitud)

          }

        })
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    async setFinalizarSolicitud({ commit }, informacionFinalizacion)
    {
      try
      {
        console.log(informacionFinalizacion)

        const db = firebase.database()

        const { subirImagen } = useImagen()

        if(informacionFinalizacion.informacionRecoleccion.fotoRecoleccion !== undefined && informacionFinalizacion.informacionRecoleccion.fotoRecoleccion !== '')
        {
          //Subir imagen a storage
          const imagenRecoleccion = {
            photoURL: await subirImagen(informacionFinalizacion.informacionRecoleccion.fotoRecoleccion),
          }

          informacionFinalizacion.informacionRecoleccion.fotoRecoleccion = imagenRecoleccion.photoURL
        }

        informacionFinalizacion.informacionRecoleccion.fechaRecogida = informacionFinalizacion.informacionRecoleccion.fechaRecogido
        informacionFinalizacion.informacionRecoleccion.fechaConcluido = informacionFinalizacion.informacionRecoleccion.fechaEnEstablecimiento
        informacionFinalizacion.informacionRecoleccion.fechaRecoleccion = informacionFinalizacion.informacionRecoleccion.fechaRecogido

        //Guardar informacion de recoleccion y de repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${ informacionFinalizacion.idSolicitud }`)
        .update({ ...informacionFinalizacion.informacionRecoleccion, ...informacionFinalizacion.informacionRepartidor, estatusSolicitud: 'Finalizado', estatusNuevoSolicitud: 'Entregado' })

        //Informacion de entrega
        for(const entrega of informacionFinalizacion.informacionEntrega)
        {
          const idEntrega = entrega.idEntrega

          if(entrega.fotoEntrega !== undefined && entrega.fotoEntrega !== '')
          {
            //
            const imagenEntrega = {
              photoURL: await subirImagen(entrega.fotoEntrega),
            }

            entrega.fotoEntrega = imagenEntrega.photoURL
          }

          delete entrega.idEntrega

          //Guardar informacion de entrega
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${ informacionFinalizacion.idSolicitud }/solicitud entrega/${ idEntrega }`)
          .update({ ...entrega, estatusEntrega: 'Finalizado', estatusNuevoSolicitud: 'Entregado' })
        }

        //commit('finalizarSolicitud', informacionFinalizacion)

        const alerta =
        {
          status: true,
          titulo: 'Solicitud finalizada',
          mensaje: 'La solicitud ha sido finalizada.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    setFechaFiltradaSolicitudes({ commit }, fecha){
      commit('setFechaFiltradaSolicitudes', fecha)
    },


    //////////////////////////////////////////////////////////////
    getDetallesSolicitudEntrega({ commit }, idEntrega)
    {
      const db = firebase.database()

      const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

      referencia//.orderByChild('idEntrega')
      //.equalTo(idEntrega)
      .on('child_added', function (snapshot)
      {
        const data = snapshot.val()

        for (let id in data['solicitud entrega'])
        {
          if(data['solicitud entrega'][id].idEntrega === idEntrega)
          {
            console.log(data)

            const informacionEntrega = {}

            informacionEntrega.entrega = data['solicitud entrega'][id]
            informacionEntrega.solicitud = data

            commit('setDetallesEntrega', informacionEntrega)
          }
        }
      })

    },
    //////////////////////////////////////////////////////////////
    getNuevosDetallesSolicitudEntrega({ commit }, idEntrega)
    {
      const db = firebase.database()

      const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

      referencia
      .on('child_changed', function (snapshot)
      {
        const data = snapshot.val()

        for (let id in data['solicitud entrega'])
        {

          if(data['solicitud entrega'][id].idEntrega === idEntrega)
          {
            console.log(data)

            const informacionEntrega = {}

            informacionEntrega.entrega = data['solicitud entrega'][id]
            informacionEntrega.solicitud = data

            commit('setDetallesEntrega', informacionEntrega)
          }

        }
      })

    }
  },
  getters:
  {
    existeUsuario(state)
    {
      return !!state.usuario
    },
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store
