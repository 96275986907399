import store from '@/state/store'

export default [
    {
        path: '/tracking/:id',
        name: 'ontrack-realtime',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/solicitudesTiempoReal/detallesTiempoReal.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const usuarioAutenticado = JSON.parse(window.localStorage.getItem('datosUsuario'));
                console.log(usuarioAutenticado);


                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    //store.dispatch('authfack/logout')
                    store.dispatch('auth/logOut')
                }

                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )

                if (usuarioAutenticado.usuario) {
                    // Navigate backoffice login
                    next({ name: 'OnTrack-login' })
                } else {
                    // Navigate back to previous page, or home as a fallback
                    next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
                }

            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/profile/details',
        name: 'profile-details',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/index.vue')
    },
    {
        path: '/profile/billing',
        name: 'billing',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/facturacion.vue')
    },
    {
        path: '/profile/address',
        name: 'address',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/profile/Directorio.vue')
    },
    {
        path: '/active',
        name: 'active',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/pedidosEnCurso.vue')
    },
    {
        path: '/pendings',
        name: 'pedidos-pendientes',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/pedidosPendientes.vue')
    },
    {
        path: '/facturas',
        name: 'facturas-clientes',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/facturasUsuarios.vue')
    },
    {
        path: '/scheduled',
        name: 'scheduled',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/pedidosProgramados.vue')
    },
    {
        path: '/history',
        name: 'history',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/pedidosHistorial.vue')
    },
    {
        path: '/jobs/:id',
        name: 'jobs',
        meta: {
            authRequired: true,
        },
        // component: () => import('../views/pages/dashboard/Solicitudes/detallesSolicitud.vue')
        component: () => import('../views/pages/dashboard/Solicitudes/detallesSolicitudRefactor.vue')
    },
    {
        path: '/delivery/:id',
        name: 'delivery',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/Solicitudes/solicitudesTiempoReal.vue')
    },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/backoffice',
        name: 'OnTrack-login',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'tiempo-real-repartidores' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
        component: () => import('../views/admin/account/loginAdmin.vue')
    },
    {
        path: '/backoffice/active',
        name: 'OnTrack-active',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/pedidosEnCurso.vue')
    },
    {
        path: '/backoffice/scheduled',
        name: 'OnTrack-scheduled',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/pedidosProgramados.vue')
    },
    {
        path: '/backoffice/history',
        name: 'OnTrack-history',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/pedidosHistorial.vue')
    },
    {
        path: '/backoffice/delivery/:id',
        name: 'delivery-admin',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/solicitudes/solicitudesTiempoReal.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/detallesPerfil.vue')
    },
    {
        path: '/backoffice/report',
        name: 'report',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/reporteSolicitudes.vue')
    },
    {
        path: '/details/:id',
        name: 'details',
        meta: {
            authRequired: true,
        },
        // component: () => import('../views/admin/solicitudes/detallesSolicitudes.vue')
        component: () => import('../views/pages/detailsSolicitudes/detallesSolicitud1.vue')
    },
    {
        path: '/backoffice/setting',
        name: 'setting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/modificarOnTrack.vue')
    },

    {
        path: '/backoffice/coberturas/detalles/:id',
        name: 'coberturas',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/detallesCobertura.vue')
    },
    {
        path: '/backoffice/notifications',
        name: 'notifications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/notificacionesUnoUno.vue')
    },
    {
        path: '/backoffice/clientesActivos',
        name: 'clientes',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/clientes.vue')
    },
    {
        path: '/backoffice/clientesInactivos',
        name: 'clientes-inactivos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/clientesInactivos.vue')
    },
    //añadido por luis
    {
        path: '/backoffice/facturas',
        name: 'all-facturas',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/detallesFactura.vue')
    },
    {
        path: '/backoffice/pendings',
        name: 'all-pendientes',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/pedidosPendientes.vue')
    },

    //
    {
        path: '/backoffice/notifications/:id',
        name: 'detalles-notifications',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/detallesNotificaciones.vue')
    },
    {
        path: '/backoffice/delivery-man',
        name: 'repartidores-conectados',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/repartidores.vue')
    },
    {
        path: '/backoffice/delivery-man/requests/approved',
        name: 'repartidores-solicitudes-aprobados',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/solicitudesRepartidorAprobadas.vue')
    },
    {
        path: '/backoffice/delivery-man/requests/banned',
        name: 'repartidores-solicitudes-rechazados',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/solicitudesRepartidorRechazadas.vue')
    },
    {
        path: '/backoffice/delivery-man/:id',
        name: 'detalles-repartidores',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/detallesRepartidores.vue')
    },
    {
        path: '/backoffice/disconnected/delivery-man',
        name: 'historial-desconectados',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/historialConexiones/repartidoresDesconectados.vue')
    },
    {
        path: '/backoffice/connected/delivery-man',
        name: 'historial-conectados',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/historialConexiones/repartidoresConectados.vue')
    },
    {
        path: '/backoffice/history/delivery-man/:id',
        name: 'detalles-conexiones',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/historialConexiones/detallesConexion.vue')
    },
    {
        path: '/backoffice/real-time/delivery-man',
        name: 'tiempo-real-repartidores',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/tiempoReal.vue')
    },
    {
        path: '/backoffice/real-time/delivery-man/:id',
        name: 'tiempo-real-repartidor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/repartidores/tiempoRealRepartidor.vue')
    },
    {
        path: '/backoffice/faq',
        name: 'faq',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/preguntasFrecuentes.vue')
    },
    {
        path: '/backoffice/new',
        name: 'support-new',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/dashboard/solicitarEntregas.vue')
    },


    /* ------------------------ Soporte Tecnico ------------------------ */
    {
        path: '/backoffice/support',
        name: 'support',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/soporte/altaTecnicos.vue')
    },
    {
        path: '/backoffice/support/members',
        name: 'support-members',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/soporte/listadoEquipo.vue')
    },
    {
        path: '/backoffice/support/chat',
        name: 'support-chat',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/soporte/chat.vue')
    },
    {
        path: '/backoffice/support/members/:id',
        name: 'detalles-support',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/admin/soporte/detallesSoporteTecnico.vue')
    },

]
